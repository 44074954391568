<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Lead</a>
                </li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">Manage Lead</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Lead</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_lead')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newLead',
              mode: 'create',
            })
          "
        >New Lead</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0">
          <select v-model="params.size" @change="filterData" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" @change="filterData">
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in leadElements.filter_branchs"
              :key="index"
              :value="branch.id"
            >{{ branch.name }}</option>
          </select>
          <select v-model="params.assigned_to" class="filter-input mr-2 mg-t-5" @change="filterData">
            <option value>Assigned to All</option>
            <option
              v-for="(user, index) in leadElements.user"
              :key="index"
              :value="user.id"
            >{{ user.name }}</option>
          </select>
          <select
            name="size"
            class="filter-input mr-2 mg-t-5"
            v-model="params.lead_by"
            @change="filterData"
          >
            <option value>Lead By</option>
            <option
              v-for="(user, index) in leadElements.user"
              :key="index"
              :value="user.id"
            >{{ user.name }}</option>
          </select>
          <div class="d-inline-block mr-2">
            <label for class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="filterData"
              v-model="params.from"
            />
            <label for class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              @change="filterData"
              v-model="params.to"
            />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="filterData"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-15p">Lead Name</th>
              <th class="wd-20p">Address</th>
              <th class="wd-10p">Phone Number</th>
              <th class="wd-10p">Status</th>
              <th class="wd-10p">Follow Date</th>
              <th class="wd-10p">To Do</th>
              <th
                class="wd-10p text-center table-end-item no-print"
                v-if="checkIfAnyPermission(['view_lead_profile','edit_lead','delete_lead'])"
              >Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && leadLists.length > 0">
            <tr v-for="(lead, index) in leadLists" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}.</th>
              <td>{{ lead.generate_date }}</td>
              <td>{{ lead.name }}</td>
              <td>{{ lead.address }}</td>
              <td>{{lead.phone_number}}</td>
              <td><span :class="'tx-'+lead.lead_status.color">{{ lead.lead_status.title }}</span></td>
              <td>{{ lead.next_follow_date }}</td>
              <td>
                <span v-if="lead.todo" :class="'badge badge-'+lead.lead_todo.color">{{ lead.lead_todo.title }}</span>
              </td>
              <td
                class="text-right table-end-item no-print"
                v-if="checkIfAnyPermission(['view_lead_profile','edit_lead','delete_lead'])"
              >
                <a
                  href="javascript:;"
                  title="View Profile"
                  v-if="checkSinglePermission('view_lead_profile')"
                  class="mr-3"
                >
                  <i class="fas fa-eye tx-success" @click="view(lead.id)"></i>
                </a>
                <a href="javascript:;" title="Edit" v-if="checkSinglePermission('edit_lead')" class="mr-3">
                  <i class="fa fa-edit" @click="edit('editLead', lead.id)"></i>
                </a>
                <a href="javascript:;" title="Delete" v-if="checkSinglePermission('delete_lead')">
                  <i class="fa fa-trash" @click="drop(lead.id)"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && leadLists.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align:center;">{{error_message}}</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <LeadCreate @parent-event="getData" />
        <LeadEdit @parent-event="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import LeadCreate from "./NewLead";
import Paginate from "vuejs-paginate";
import LeadEdit from "./EditLead";
import Services from "./Services/Services";
import _ from "lodash";
import { Search } from "../../../../mixins/search";
import { mapGetters } from "vuex";
export default {
  mixins: [Search],
  data() {
    return {
      loading: true,
      error: false,
      error_message: "",
      params: {
        size: 10,
        branch: "",
        assigned_to: "",
        lead_by: "",
        from: "",
        to: "",
        today:'',
        searched: "",
        offset: 0,
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      page: 1,
    };
  },
  components: {
    LeadCreate,
    LeadEdit,
    Paginate,
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists",
      "authData",
      "dataLists2",
      "dataLists3",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("leads", ["leadLists", "leadElements"]),
  },

  beforeMount() {
    this.params.assigned_to = this.authData.user.id;
    this.params.today = this.today;
    this.getData();
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    view(id) {
      this.$router.push(`/lead/profile/${id}`);
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      // this.$store.commit("setDataId", id);
      // this.$store.commit("dropRecord", `api/lead/${id}`);
      this.$swal({
        title: "Do you really want to delete Lead ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // delete lead
          return Services.deleteLead(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.getData();
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getData() {
      this.loading = true;
      Services.getFilterLeadData(this.params)
        .then((res) => {
          this.$store.commit("leads/setLeadLists", res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.getElements();
        });
    },
    getElements() {
      Services.getLeadElements()
        .then((res) => {
          this.$store.commit("leads/setLeadElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterData: _.debounce(function () {
      this.loading = true;
      this.params.today = '';
      Services.getFilterLeadData(this.params)
        .then((res) => {
          this.$store.commit("leads/setLeadLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = true;
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
        });
    }, 1000),
  },
};
</script>
<style scoped>
</style>